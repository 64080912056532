<section class="accordion" @fadeIn>
  <div class="wrapper">
    <div class="accordion-item">
      <div class="title" (click)="isTermsAndConditionsOpen = !isTermsAndConditionsOpen">
        <span>Terms & conditions</span>
        <app-svg-icon [icon]="isTermsAndConditionsOpen ? 'chevron-up' : 'chevron-down'" size="small"></app-svg-icon >
      </div>
      <div class="description" [class.active]="isTermsAndConditionsOpen">
        <h5 class="mb-20">Please look before you book</h5>
        <p>Superfare tickets are great when you want to get somewhere fast for less. However, there are some things you need to know before you go.</p>
        <h5 class="mb-20">First of all</h5>
        <p>Superfare tickets aren’t available everywhere and every day. To buy, search for Superfare tickets only at <a [routerLink]="['/']">avantisuperfare.co.uk</a>. Some days may not be available if there are no empty seats to fill. Superfare tickets are valid on Avanti West Coast (TOC Code: VT) services only. You must travel on the specific train we book for you. Seat reservations are compulsory, but don’t worry, we’ll sort this for you.</p>
        <h5 class="mb-20">Just as important</h5>
        <ol>
          <li>You can only buy Superfare tickets from <a [routerLink]="['/']">avantisuperfare.co.uk</a>.</li>
          <li>Superfare tickets are subject to availability and certain blackout times and dates apply, for example days when there are engineering works. Availability is higher on weekdays and more limited on weekends. </li>
          <li>Superfare tickets are Standard Class single tickets. They are one way only.</li>
          <li>You can travel from London to Birmingham (£9), London to Birmingham International (£9), London to Carlisle (£28), London to Coventry (£9), London to Crewe (£14), London to Glasgow (£30), London to Lancaster (£25), London to Liverpool (£15), London to Macclesfield (£16), London to Manchester (£20), London to Nuneaton (£9), London to Oxenholme (£26), London to Penrith (£26), London to Preston (£22), London to Rugby (£9), London to Runcorn (£15), London to Stafford (£12), London to Stockport (£20), London to Stoke-on-Trent (£14), London to Warrington Bank Quay (£15), London to Wigan North Western (£20), London to Wilmslow (£18), London to Wolverhampton (£9), Manchester to Milton Keynes (£18), or Preston to Glasgow (£12). Or the other way round.</li>
          <li>You can book up to 56 days before your departure date, but no later than 7 days before.</li>
          <li>You must choose between a morning (07:00 to 11:59), afternoon (12:00 to 16:59) or evening (17:00 to 21:00) time slot at time of booking. 24 hours before you go, we’ll match you to an empty seat and you’ll get an email confirming your train departure time.</li>
          <li>Break of journey is not permitted, so you can only go on the train we book for you. This means you can’t break your journey by hopping off and getting on another train later.</li>
          <li>You must travel to and from the destination on your ticket. You cannot get off or on at an earlier or later stop.</li>
          <li>You can buy a maximum of 9 tickets per booking. We can’t promise that all passengers in the same booking will be seated together, this is subject to availability.  If you have any additional travel needs such as requiring a wheelchair accessible seat, please ring our call centre on <a href="tel:0345 528 0253">0345&nbsp;528&nbsp;0253</a>.</li>
          <li>Child and other discounts aren’t available on Superfare tickets. </li>
          <li>You also can’t use Railcards, or another offer or promotion when you buy Superfare tickets.</li>
          <li>There are no refunds or exchanges. Please double check your ticket before you buy.</li>
          <li>Prices are valid until 31/08/2025.</li>
          <li>Superfare tickets are eligible to Delay Repay.</li>
          <li>Superfare tickets don’t count towards Club Avanti rewards.</li>
          <li>Bookings are subject to our terms and conditions and the National Rail Conditions of Travel.</li>
        </ol>
        </div>
    </div>
    <div class="accordion-item">
      <div class="title" (click)="isFAQOpen = !isFAQOpen">
        <span>FAQs</span>
        <app-svg-icon [icon]="isFAQOpen ? 'chevron-up' : 'chevron-down'" size="small"></app-svg-icon >
      </div>
      <div class="description" [class.active]="isFAQOpen">
        <h5 class="mb-20">What is Superfare?</h5>
        <p>A Superfare ticket is a ticket type that gives you a great price for trains that have spare capacity. We pick your train; you bag a bargain ticket and make a lonely seat happy. Superfare tickets are for our comfy Standard Class and for one way travel only.</p>
        <h5 class="mb-20">How much is it?</h5>
        <p>
          London – Birmingham £9<br>
          London – Birmingham International £9<br>
          London – Carlisle £28<br>
          London – Coventry £9<br>
          London – Crewe £14<br>
          London – Glasgow £30<br>
          London – Lancaster £25<br>
          London – Liverpool £15<br>
          London – Macclesfield £16<br>
          London – Manchester £20<br>
          London – Nuneaton £9<br>
          London – Oxenholme £26<br>
          London – Penrith £26<br>
          London – Preston £22<br>
          London – Rugby £9<br>
          London – Runcorn £15<br>
          London – Stafford £12<br>
          London – Stockport £20<br>
          London – Stoke-on-Trent £14<br>
          London – Warrington Bank Quay £15<br>
          London – Wigan North Western £20<br>
          London – Wilmslow £18<br>
          London – Wolverhampton £9<br>
          Manchester – Milton Keynes £18<br>
          Preston – Glasgow £12<br>
        </p>

        <h5 class="mb-20">Can I choose when I go?</h5>
        <p>You can pick the day and the time slot in which you want to travel – either morning (07:00 to 11:59), afternoon (12:00 to 16:59) or evening (17:00 to 21:00). It’s up to you.</p>


        <h5 class="mb-20">How do I know what train I’m getting?</h5>
        <p>You’ll always know what day you’re travelling. We’ll tell you the exact time your train departs at least 24 hours in advance. Just keep an eye out for our email.</p>


        <h5 class="mb-20">Where can I go with a Superfare ticket?</h5>
        <p>Superfare tickets are for single journeys from London to either Glasgow, Preston, Manchester, Liverpool, Birmingham, Stafford, Crewe, Stoke-on-Trent, Warrington, Runcorn, Macclesfield, Wilmslow, Stockport, Wigan or Lancaster. Or the other way round. New destinations may be available soon, so watch this space.</p>


        <h5 class="mb-20">Can I get on or off at a different stop?</h5>
        <p>Your ticket is only valid for travel to and from the destination on your ticket. You cannot get off or on at an earlier stop.</p>


        <h5 class="mb-20">How do I book a Superfare ticket?</h5>
        <p>Superfare tickets are only available at <a [routerLink]="['/']">avantisuperfare.co.uk</a>. Go to
          <a [routerLink]="['/']">avantisuperfare.co.uk</a> and:</p>

            <ol>
              <li><b>Decide where you want to go</b><br> London, Manchester, Liverpool, Preston, Glasgow, Birmingham, Stafford, Crewe, Stoke-on-Trent, Warrington, Runcorn, Macclesfield, Wilmslow, Stockport, Wigan or Lancaster?</li>
              <li><b>Select the number of tickets</b><br> You can buy up to nine Superfare tickets in one booking for the same train. We can’t promise you’ll all be seated together as this is subject to availability, but we’ll do our best. If you have any additional travel needs such as requiring a wheelchair accessible seat, please ring our call centre on <a href="tel:0345 528 0253">0345&nbsp;528&nbsp;0253</a>.</li>
              <li><b>Pick the date you want to travel</b><br> Some days may not be available if there are no empty seats to fill.</li>
              <li><b>Choose a morning, afternoon or evening departure</b><br>Early riser? More of a night owl? Fancy a chilled afternoon on the train? You do you.
                <ul>
                  <li>Morning trains go between 7:00 and 11:59</li>
                  <li>Afternoon trains go between 12:00 and 16:59</li>
                  <li>Evening trains go between 17:00 and 21:00</li>
                </ul>
              </li>
              <li><b>Head to the checkout and confirm your reservation</b><br> We’ll send you an email confirming everything too. Keep it safe.</li>
              <li><b>Find out your train time</b><br> You’ll get an email confirming your train departure time 24 hours before you go.</li>
            </ol>


        <h5 class="mb-20">When can I book Superfare tickets?</h5>
        <p>The earliest you can book is 56 days before the day you want to travel. The latest you can book is 7 days before your departure date. Not all days will be available 56 days in advance, such as dates with engineering works. Please check <a href="https://www.avantiwestcoast.co.uk/travel-information/plan-your-journey/timetables" target="_blank">here</a> for more information on when dates will be available.</p>


        <h5 class="mb-20">Are Superfare tickets always available?</h5>
        <p>As we are matching you to an empty seat, book early to avoid disappointment. Availability is higher on weekdays and more limited on weekends.</p>

        <h5 class="mb-20">Can I change Superfare tickets or get a refund?</h5>
        <p>Superfare tickets can’t be changed or refunded or exchanged. You can only go on a different train or date if you buy another ticket. Please double check everything before you book.</p>


        <h5 class="mb-20">Can I use my railcard or other discounts?</h5>
        <p>Sorry, Railcards and other discount can’t be used to buy Superfare tickets.</p>


        <h5 class="mb-20">How many Superfare tickets can I buy in one booking?</h5>
        <p>You can buy a maximum of 9 Superfare tickets per booking. We can’t promise that all passengers in the same booking will be seated together, this is subject to availability. Please make sure you check your seat allocations before travelling. If you have any seating requests, please ring our call centre on <a href="tel:0345 528 0253">0345&nbsp;528&nbsp;0253</a>.</p>


        <h5 class="mb-20">Will I be seated with the other people in my booking?</h5>
        <p>Whilst you will all be allocated the same train, we can’t promise that all passengers in the same booking will be seated together, because this is subject to availability. Please make sure you check your seat allocations before travelling. If you have any seating requests, please ring our call centre on <a href="tel:0345 528 0253">0345&nbsp;528&nbsp;0253</a>.</p>


        <h5 class="mb-20">Can I travel with children?</h5>
        <p>Yes of course. However, little ones need a Superfare ticket too at the same price as an adult. Please bear in mind that this means they will have a seat reservation, but we can’t promise you’ll be in the same carriage as this is subjective to availability. Please make sure you check your seat allocations before travelling. If you have any seating requests, please ring our call centre on <a href="tel:0345 528 0253">0345&nbsp;528&nbsp;0253</a>.</p>
        <p>We want to make sure that everyone on our trains is safe and comfortable, so we don’t recommend that children under the age of 13 travel alone. Younger children should only travel on Avanti West Coast trains with an adult. If our staff see a child alone on one of our trains or they have any concerns, they’ll contact the British Transport Police for help.</p>


        <h5 class="mb-20">What if my train is delayed?</h5>
        <p>If your train is more than 15 minutes late, you can usually claim compensation with Delay Repay. Just log in or register <a href="https://delayrepay.avantiwestcoast.co.uk/" target="_blank">here</a> and follow the simple steps to claim.</p>


        <h5 class="mb-20">What if my train is cancelled?</h5>
        <p>You can use your ticket on one of the two Avanti West Coast services to the same destination immediately before or after your booked, cancelled train. Unfortunately, your seat reservation is only for your pre-booked service and can’t be carried over. Please speak with our station or onboard team to find out where’s best to get a seat.</p>

        <p>Alternatively, if you choose not to travel, you are able to request a refund by contacting Seatfrog at <a href="mailto:{{ emailAddress }}" target="_blank">{{ emailAddress }}</a>.</p>

        <h5 class="mb-20">What if there's a strike?</h5>
        <p>Don’t worry. If you’ve booked to travel and then there’s a strike, we’ll automatically refund you your money.</p>

        <h5 class="mb-20">Is there assistance available for my journey?</h5>
        <p>By letting us know your travel needs in advance, we can help make sure you have a hassle-free journey. You can book up to two hours before your journey. Tell us when you’re planning to travel and we can:</p>
        <ul>
          <li>Arrange assistance for you</li>
          <li>Help you on and off our trains</li>
          <li>Reserve wheelchair spaces and seats</li>
          <li>Provide detailed rail information to smooth your journey</li>
          <li>Help with any animals or pets travelling with you</li>
        </ul>


        <h5 class="mb-20">How do I book Passenger Assist? </h5>
        <p>The easiest way to organise the support you need is by using the Transreport App. Click the links below to download:</p>
        <ul>
          <li><a href="https://apps.apple.com/gb/app/passenger-assistance/id1542190496" target="_blank">Apple Store</a></li>
          <li><a href="https://play.google.com/store/apps/details?id=uk.co.transreport.pa" target="_blank">Google Play</a></li>
        </ul>
        <p class="mt-25">Alternatively, you can book via our <a href="https://www.railhelp.co.uk/avantiwestcoast/passenger-assist" target="_blank">online booking form</a> or ring our call centre on <a href="tel:0345 528 0253">0345&nbsp;528&nbsp;0253</a>.</p>

      </div>
    </div>
    <div class="accordion-item">
      <div class="title" (click)="isNeedMoreHelpOpen = !isNeedMoreHelpOpen">
        <span>Need more help?</span>
        <app-svg-icon [icon]="isNeedMoreHelpOpen ? 'chevron-up' : 'chevron-down'" size="small"></app-svg-icon >
      </div>
      <div class="description" [class.active]="isNeedMoreHelpOpen">

        <h5 class="mb-20">What if there’s a problem with my booking?</h5>
        <p>
          If it’s less than 24 hours before you go and you’re yet to receive your e-Ticket, please double check your inbox (including the spam folder) and if you have not received it, please contact Seatfrog at <a href="mailto:{{ emailAddress }}" target="_blank">{{ emailAddress }}</a>.
        </p>
        <p>
          If it’s less than 24 hours before you go and you have received your e-Ticket but need additional support ahead of your journey, please ring our call centre on <a href="tel:0345 528 0253">0345&nbsp;528&nbsp;0253</a>.
        </p>
        <p>
          For all queries more than 24 hours from departure, please contact Seatfrog at <a href="mailto:{{ emailAddress }}" target="_blank">{{ emailAddress }}</a>.
        </p>
      </div>
    </div>
  </div>
</section>